import { hasSelectedFacets, getSort } from "./modules/search";
import {
  isFacetedNavPage,
  getCategoryStatusIsNotFound
} from "./modules/category/selectors";
import { getLocation } from "../state/modules/router/selectors";

export const shouldRenderPagination = state => {
  const isCategoryStatusIsNotFound = getCategoryStatusIsNotFound(state);
  if (isCategoryStatusIsNotFound) {
    return false;
  }

  const location = getLocation(state);
  const isCtaUrl = !!location.pathname.includes("ctas");

  return (
    !isCtaUrl &&
    (isFacetedNavPage(state) || !hasSelectedFacets(state)) &&
    !getSort(state)
  );
};
